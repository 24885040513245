.left-sidebar {
  width: 250px;
  height: 100vh;
  background: var(--lighter-blue);
  padding-left: 4rem;
  padding-top: 5rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.brand-logo img {
  height: 30px;
  width: 60px;
}

.left-sidebar nav ul {
  list-style: none;
  padding: 0;
}

.left-sidebar nav ul li a {
  padding-top: 2rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 1rem;
  align-items: baseline;
  transition: 0.5s ease-in;
  color: var(--shadeAsh);
}

.left-sidebar nav ul li a:hover {
  color: var(--shade-black);
}

.left-sidebar nav ul li a:hover > svg path {
  fill: var(--shade-black);
}

.active {
  color: var(--shade-black) !important;
  font-weight: 700;
}

.left-sidebar nav ul li a svg path {
  fill: var(--shade-ash);
  transition: 0.5s ease-in;
}

.active svg path {
  fill: var(--shade-black) !important;
}

.logout {
  position: absolute;
  bottom: 2rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 1rem;
  align-items: center;
  color: var(--shade-ash);
}

.logout:hover {
  color: var(--shade-ash);
}

.close-nav {
  display: none;
}

@media screen and (max-width: 820px) {
  /* .left-sidebar {
    display: none;
  } */

  .close-nav {
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
  }
}
