.topNav{
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 10;
    height: 60px;
}

.bottomNav {
    height: 60px;
    background: transparent;
    margin-top: 3.75rem;
}

.topNav nav {
    display: flex;
    justify-content: flex-end;
    /* position: fixed;
    top: 0; */
}

.bottomNav {
    /* height: 60px; */
    height: fit-content;
    background: #C0E7F8;
}

.topNav nav, .bottomNav nav {
    height: 100%;
}
.logo{
    width: 175px;
    object-fit: cover;
    /* width: 85px; */
}

.topNav nav ul, .bottomNav nav ul {
    display: flex;
    justify-content: flex-end;
    padding: 21px 44px 21px 44px;
    align-items: center;
    height: 100%;
    list-style-type: none;
    gap: 20px;
    /* width: 100%; */
}

.topNav nav ul li a {
    color: black !important;
    font-weight: bold;
}

.bottomNav nav ul {
    justify-content: space-between;
    align-items: center;
}

.bottomNav nav ul li:last-child {
    display: flex;
    align-items: center;
    gap: 10px;
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .topNav nav ul, .bottomNav nav ul {
        padding: 5px 10px 5px 20px;
    }

    .topNav nav {
        justify-content: space-between;
    }
}