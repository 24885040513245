.step-dropdown {
  margin-top: 1rem;
}

.step-heading {
  background: var(--lighter-blue);
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.step-detail {
  display: flex;
  /* margin-left: 1rem; */
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}

.step-detail .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: var(--button-color);
}

.step-detail .step__title {
  color: var(--regular-black);
  font-weight: 700;
  display: block;
  font-size: 1rem;
}

.step-children {
  transition: display 0.5s linear;
}

.proceed-btn{
  background-color: var(--button-color);
  /* min-height: 35px; */
  display: block;
  min-width: 100px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 3px;
  color: var(--regular-white);
  font-weight: 600;
}

.proceed-btn:hover{
  color: var(--regular-white);
}


@media screen and (max-width:768px) {
  .step-dropdown{
    margin-top: 0;
  }
}
.step-heading{
  /* padding-top: 20px; */
  padding-top: 15px;
  padding-bottom: 15px;
}
.indicator{
  margin-right: -15px !important;
}


