:root {
    --font-size: 17px;
    --link-color: rgba(1, 1, 1, 0.64);
    --button-color: #FBA834;
    --button-height: 45px;
    --button-border: rgba(95, 95, 95, 0.39);
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .authLayout__bg {
        display: none !important;
    }

    .authLayout__content {
        width: 100vw !important;
    }
}

.authLayout {
    min-height: 100vh;
    height: 100vh;
    background-color: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
}

.authLayout__wrapper {
    display: flex;
    height: inherit;
}

.authLayout__content {
    width: 55%;
}

.authLayout__bg {
    width: 45%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background: url(../../../assets/img/AsianHHAwithClient.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.authLayout__content .authFormContainer {
    width: 70%;
    margin: 70px auto;
}

.authFormContainer .tabLinks ul {
    list-style-type: none;
    display: flex;
    gap: 40px;
    color: var(--link-color);
    padding: 0;
}

.authFormContainer .tabLinks ul li a {
    color: var(--link-color);
    font-size: var(--font-size);
}

.authFormContainer .tabLinks ul li a.active {
    font-weight: 700;
    position: relative;
}

.authFormContainer .tabLinks ul li  a.active:before {
    content: '';
    position: absolute;
    background: var(--link-color);
    height: 3px;
    width: 100%;
    bottom: -10px;
}

.authFormContainer .tabContent {
    margin-top: 26px;
    padding-bottom: 4rem;
}

.authFormContainer a.active {
    color: #4EB3E5;
}

.authFormContainer .ant-btn-primary {
    width: 100%;
    height: var(--button-height) !important;
    background: var(--button-color) !important;
    font-size: 16px;
    border-color: var(--button-border) !important;
    border-radius: 3px !important;
    letter-spacing: 0.06em;
}
