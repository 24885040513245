.search-wrapper {
  background: var(--lighter-blue);
  max-width: 300px;
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
}

.search-input {
  background: "transparent";
  border: none;
  outline: none;
}
