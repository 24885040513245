:root {
  --background: #f0f9fd;
}

.appLayout {
  background: var(--background);
}

.appLayoutWrapper {
  display: flex;
  /* justify-content: space-between; */
  gap: 45px;
  padding: 20px 66px 69px 66px;
  flex-wrap: wrap;
}
.addressItem{
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.imgSpace {
position: relative;
width: 180px;
height: 220px;
background: rgba(192, 231, 248, 0.29);
border: 1px solid rgba(26, 16, 6, 0.15);
border-radius: 2px;
/* left: 565.59px;
top: 0px; */
cursor: pointer;
text-align: center;
display: flex;
flex-direction: column;
/* justify-content: center; */
/* padding-top: 1rem; */
box-sizing: border-box;
border-radius: 2px;
}

.appSidebar {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  position: sticky;
  top: 20px;
  height: max-content;
}

.appSidebarBox {
  background: transparent;
  border: 1px solid rgba(101, 178, 212, 0.83);
}

.appSidebarBoxHeader {
  padding: 36px 27px;
}

.appSidebarBoxHeader h6 {
  font-size: 20px;
  font-weight: bold;
}

.appSidebarBoxHeader span {
  font-size: 13px;
  font-weight: bold;
  color: rgba(1, 1, 1, 0.64);
  padding-top: 10px;
}

.appSidebarBoxList ul {
  list-style-type: none;
  padding: 0;
  counter-reset: css-counter 0;
}

.appSidebarBox li {
  height: 52px;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.appSidebarBox li a {
  background: inherit;
  height: inherit;
  width: inherit;
  padding-left: 26px;
  color: rgba(1, 1, 1, 0.53);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.appSidebarBox li .listLinkActive {
  background: #1896ce !important;
  color: white;
  font-weight: bold;
  position: relative;
}

.appSidebarBox li .listLinkActive::before {
  position: absolute;
  content: "";
  left: 0;
  height: 100%;
  width: 5px;
  background: #fba834;
}

.uploadIcon svg {
  fill: rgba(58, 43, 28, 0.64);
  font-size: 30px;
}
.addNewItemWrapper{
    display: flex;
    justify-content: space-between;
    /* padding-right: 10px; */

}
.addNewItemWrapper button {
  display: flex;
  align-items: center;
  max-width: 100px;
  gap: 10px;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: rgba(1, 1, 1, 0.63);
  cursor: pointer;
  margin-bottom: 30px;
  border: 1px solid rgba(1, 1, 1, 0.63);
}

.formItemHeader {
  padding: 20px;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.06em;

  color: #4eb3e5;
}

.appSidebarBackLink {
  display: flex;
  gap: 10px;
  align-items: center;
}

.appSidebarBox li a span {
  counter-increment: css-counter 1;
}

.appSidebarBox li a span::before {
  content: "0" counter(css-counter);
  padding-right: 21px;
}

.appSidebarBox li .listLinkActive span::before {
  content: url(../../../assets/img/dot.svg);
  padding-left: 10px;
  margin-right: 25px;
  padding: 0;
  padding-left: 5px;
}

.appContent {
  background: white;
  width: calc(100% - 400px);
  padding: 84px 32px 20px 32px;
}

.appContent form {
  padding-top: 30px;
}

/* .mainContent span {
  font-size: 12px;
  font-weight: bold;
} */

.mainContent h2 {
  font-size: 34px;
  font-weight: bold;
}

.formFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.formFooter .formSave {
  display: flex;
  gap: 10px;
}

.formFooter .formPrevious button {
  background: transparent !important;
  border: none;
  box-shadow: none;
  color: #fba834;
  padding-left: 0;
}

.formPrevious button span {
  font-size: 16px !important;
}

.formSave button {
  background: #fba834 !important;
  background: #fba834;
  color: white;
  padding: 13px 16px;
  height: 100%;
  border-radius: 3px;
  border-color: #fba834;
  width: 10em !important;
}

.formSave button span {
  font-size: 16px;
  font-weight: normal;
}

.formSave button:first-child {
  background: white !important;
  color: #fba834;
}

/** mobile layout **/
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .appSidebar,
  .appContent {
    width: 100%;
  }

  .formSave button {
    width: 8em !important;
    display: flex;
    justify-content: center;
  }

  .updateForm {
    padding-top: 25px;
    margin-top: 30px;
  }

  .formPrevious button span,
  .formSave button span {
    font-size: 14px !important;
  }

  .appLayoutWrapper {
    padding: 20px;
  }

  .mainContent h2 {
    font-size: 5vw;
  }

  .appContent {
    padding: 20px;
    margin-top: 100px;
  }

  .appSidebar {
    /* display: none; */
    position: relative;
    z-index: 5;
    background-color: white;
    border: 1px solid white;
    top: 0 !important;
    /* top: -10px; */
  }

  .mainContent > span,
  .mainContent h2 {
    top: -130px;
    left: -20px;
    position: relative;
  }

  .mainContent form {
    margin-top: 30px;
  }

  .mainContent > form:first-of-type {
    margin-top: -70px;
  }

  .appSidebarBackLink span,
  .appSidebarBackLink b {
    color: #fba834;
  }

  .appSidebarMobileWrapper {
    height: 100vh;
    background-color: red;
    position: relative;
    width: 100%;
    z-index: 3;
    background: rgba(24, 150, 206, 0.31);
    backdrop-filter: blur(2px);
  }

  .appSidebarMobileClose {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    padding: 10px;
  }
}
@media only screen and (min-width : 320px) and (max-width : 768px){
    .appSidebar, .appContent{
        width: 100%;
    }
    .imgSpace {
        /* position: absolute; */
    /* max-width: 164.53px; */
    /* width: 100vw !important; */
    font-size: 10px;
    /* background-color: red; */
    /* left: 565.59px;
    top: 0px; */

    }
    .formSave button {
        width: 8em !important;
        display: flex;
        justify-content: center;
    }

    .updateForm {
        padding-top: 25px;
        margin-top: 30px;
    }

    .formPrevious button span, .formSave button span {
        font-size: 14px !important;
    }

    .appLayoutWrapper {
        padding: 20px;
    }

    .mainContent h2{
        font-size: 5vw;
    }

    .appContent {
        padding: 20px;
        margin-top: 100px;
    }

    .appSidebar {
        /* display: none; */
        position: relative;
        z-index: 5;
        background-color: white;
        border: 1px solid white;
        top: 0 !important;
        /* top: -10px; */
    }

    .mainContent > span, .mainContent h2 {
        top: -130px;
        left: -20px;
        position: relative;
    }

    .mainContent form {
        margin-top: 30px;
    }

    .mainContent > form:first-of-type {
        margin-top: -70px;
    }

    .appSidebarBackLink span, .appSidebarBackLink b{
        color: #FBA834;
    }

    .appSidebarMobileWrapper {
        height: 100vh;
        background-color: red;
        position: relative;
        width: 100%;
        z-index: 3;
        background: rgba(24, 150, 206, 0.31);
        backdrop-filter: blur(2px);
    }

    .appSidebarMobileClose {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        padding: 10px;
    }
}
