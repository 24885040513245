.addressItem{
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
 }
 b{
    font-size: large;
 }
 .termsModal{
    font-size: 15px;
 }

 @media screen and (max-width:768px) {
    .termsModal{
        font-size: 14px;
     }
 }