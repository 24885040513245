:root {
    --font-size: 17px;
    --link-color: rgba(1, 1, 1, 0.64);
    --button-color: #FBA834;
    --button-height: 45px;
    --button-border: rgba(95, 95, 95, 0.39);
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .defaultLayoutContent{
        width: 95vw !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.defaultLayout {
    background-image: url(../../../assets/img/AsianHHAwithClient.png);
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    overflow-y: scroll;
}

.defaultLayout::after {
    /* //overlay background */
    content: '';
    min-height: inherit;
    height: inherit;
    width: inherit;
    background: rgba(10, 35, 48, 0.82);;
    position: fixed;
    left: 0;
    top: 0;
}

.defaultLayoutWrapper {
    position: relative;
    z-index: 3;
    height: inherit;
}

.defaultLayoutContent {
    width: 45vw;
    height: fit-content;
    background: white;
    margin: 5% auto;
    border-radius: 26px;
    padding-left: 60px;
    padding-right: 58px;
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardHeader {
    font-size: 24px;
    font-weight: 700 !important;
}

.cardDescription {
    font-size: 16px;
    color: rgba(1, 1, 1, 0.72);
}