.section-desc[data-v-42314ea7]{
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.section-desc__heading[data-v-42314ea7]{
  color: var(--text-Grey);
  display: block;
  font-weight: 800;
  font-size: 1rem;
}
.section-desc__info[data-v-42314ea7]{
  display: block;
  color: var(--text-blue);
  font-style: italic;
  font-weight: 500;
}
.label-tooltip[data-v-42314ea7]{
  display: flex;
  grid-gap: 1px;
  gap: 1px;
  font-size: 0.8rem;
  align-items: center;
}