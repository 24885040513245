@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700,200);
@import url(https://fonts.googleapis.com/css?family=Roboto:300);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css);
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --input-label-color: #010101a3;
  --link-color: rgba(1, 1, 1, 0.64);
  --button-color: #fba834;
  --button-text-color: #000000;
  --button-height: 45px;
  --button-border: rgba(95, 95, 95, 0.39);

  /* ------------ blue color shades ----------- */
  --text-blue: #4eb3e5;
  --text-blue-2: #429ECB;
  --light-blue: #c0e7f8;
  --lighter-blue: #f0f9fd;
  --lighter-blue-2: rgba(78, 179, 229, 0.15);

  /* ---------- grey color shades --------- */
  --text-Grey: rgba(58, 43, 28, 0.74);

  /* ----------- ash color shades ------------ */
  --shade-ash: rgba(23, 18, 25, 0.6);
  --shade-ash-2: #c4c4c4;

  /* ---------- black color shades ------------ */
  --shade-black: #010101;
  --shade-black-2: #171219;
  --shade-black-3: rgba(23, 18, 25, 0.81);
  --regular-black: #000000;

  /* ----------- white color shades ---------- */
  --regular-white: #ffffff;

  /* --------- yellow color shades ----------- */
  --light-yellow: #fba83487;
}

/** font sizing **/
@media only screen and (min-width: 320px) and (max-width: 768px) {
  h5 {
    font-size: 5vw !important;
  }
  h6 {
    font-size: 4vw !important;
  }
}
* {
  font-family: "Lato", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
}

.register-tooltip{
  color: #ff9500;
  margin-left: 6px;
  cursor: pointer;
}
.hcrDocs{
  height: 250px;
  width: 250px;
}
.ant-toolTip{
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background-color: unset;
}
/* //ant overrides // */


.ant-input {
  height: 45px !important;
  border-radius: 3px !important;
  border: 1px solid rgba(95, 95, 95, 0.39);
}

.ant-select,
.ant-select-selector {
  height: 45px !important;
}

.ant-select-selector {
  border-radius: 3px !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-form-item-label label {
  color: #010101a3 !important;
  color: var(--input-label-color) !important;
  letter-spacing: 0.06em;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
  padding-left: 11px !important;
  border: 1px solid rgba(95, 95, 95, 0.39) !important;
  border-radius: 3px !important;
  height: 45px !important;
}

.ant-input-affix-wrapper input {
  height: inherit !important;
  background-color: transparent !important;
}

.ant-input-suffix {
  padding: 11px !important;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.auth-btn--custom-auth,
.ant-picker {
  width: 100%;
  height: 45px !important;
  height: var(--button-height) !important;
  background: #fba834 !important;
  background: var(--button-color) !important;
  color: #000000 !important;
  color: var(--button-text-color) !important;
  font-size: 16px;
  border-color: rgba(95, 95, 95, 0.39) !important;
  border-color: var(--button-border) !important;
  border-radius: 3px !important;
  letter-spacing: 0.06em;
}

.ant-picker {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.bold {
  font-weight: bold;
}

.text--blue {
  color: #4eb3e5;
  color: var(--text-blue);
}

.form-heading {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: rgba(58, 43, 28, 0.74);
  color: var(--text-Grey);
  display: block;
}

.img-temp {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #c4c4c4;
  background: var(--shade-ash-2);
}

.img-temp--mobile {
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
}

.btn {
  color: #000000 !important;
  color: var(--button-text-color) !important;
  background: #fba834 !important;
  background: var(--button-color) !important;
  min-height: 45px;
  min-width: 120px;
  border-radius: 5px !important;
  font-weight: 700;
}

.btn:disabled {
  opacity: 0.5;
}

/* ------------ padding & margin classes ----------- */
.ml-auto {
  margin-left: auto;
}

/* ----------- display helpers ---------- */
.block {
  display: block;
}

.main[data-v-d9198839]{
  background: linear-gradient(180deg, rgba(9, 75, 104, 0.7) 0%, rgba(7, 55, 76, 0.196) 134.98%), url(/static/media/404-bg.d7b4f681.png);
  height: 100vh;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading[data-v-d9198839]{
  font-size: 10rem;
  font-weight: 600;
  color: var(--button-color);
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.not-found[data-v-d9198839]{
  color: var(--regular-white);
  text-transform: uppercase;
  font-size: 3rem;
  display: block;
  text-align: center;
  font-weight: 600;
}
.not-found-details[data-v-d9198839]{
  color: var(--regular-white);
  text-align: center;
  font-size: 1rem;
}
.return-btn[data-v-d9198839]{
  display: block;
  min-width:200px;
  background: var(--regular-white);
  text-align: center;
  padding: 0.8rem;
  color: #201F1F;
  border: 2px solid #010101;
  box-shadow: 3px 4px #fba834, 5px 6px #201F1F ;
}

/* box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px; */
.dashboard-wrapper[data-v-54ab280f] {
  padding: 2rem;
  padding-top: 3rem;
}
.welcome-wrapper[data-v-54ab280f] {
  background: var(--light-blue);
  border-radius: 5px;
  margin-top: 1.5rem;
  padding: 2rem 1.5rem;
}
.welcome-wrapper h1[data-v-54ab280f] {
  font-size: 2rem;
  font-weight: 500;
  color: var(--shade-black-2);
  margin-bottom: 0;
}
.welcome-wrapper span[data-v-54ab280f] {
  margin-top: -0.5rem;
  font-size: 1rem;
  display: block;
  font-weight: 500;
  color: var(--shade-black-2);
}
.applicants-todo[data-v-54ab280f] {
  margin-top: 1rem;
}
.applicants-todo h2[data-v-54ab280f] {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--regular-black);
}
.missing-table[data-v-54ab280f] {
  background: var(--light-blue);
}
.missing-table table thead tr th[data-v-54ab280f] {
  background: var(--light-blue) !important;
  font-weight: 700;
}
.missing-table table tbody tr[data-v-54ab280f] {
  background: var(--light-blue) !important;
}
@media screen and (max-width:560px) {
.home-user-name[data-v-54ab280f]{
    font-size: 25px !important;
    font-weight: 600;
    margin-bottom: 10px !important;
}
}

.skip:link {
  position: fixed;
  left: 3rem;
  color: var(--shade-black) !important;
  transition: 0.3s ease-in;
  z-index: 2050;
  font-size: 1rem;
  font-weight: 300;
  background-color: var(--light-blue);
  text-decoration: none !important;
  top: -12rem;
  outline: none;
  padding: 0 2rem;
}

.skip:focus {
  top: 0;
  color: var(--shade-black);
}

.skip:hover {
  color: var(--shade-black);
}

.main-section {
  margin-left: 250px;
  margin-right: 250px;
}

.mobile-profile-icons {
  display: none;
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 820px) {
  .main-section {
    margin-left: 0;
    /* margin-right: 0; */
  }

  .mobile-nav {
    padding: 1rem;
    background: var(--lighter-blue);
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 60px;
  }
  .main-section{
    margin-top: 4rem;
  }
}

@media screen and (max-width: 428px) {
  .main-section {
    margin-right: 0;
  }

  .mobile-profile-icons {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
  }
}

.left-sidebar {
  width: 250px;
  height: 100vh;
  background: var(--lighter-blue);
  padding-left: 4rem;
  padding-top: 5rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.brand-logo img {
  height: 30px;
  width: 60px;
}

.left-sidebar nav ul {
  list-style: none;
  padding: 0;
}

.left-sidebar nav ul li a {
  padding-top: 2rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 1rem;
  align-items: baseline;
  transition: 0.5s ease-in;
  color: var(--shadeAsh);
}

.left-sidebar nav ul li a:hover {
  color: var(--shade-black);
}

.left-sidebar nav ul li a:hover > svg path {
  fill: var(--shade-black);
}

.active {
  color: var(--shade-black) !important;
  font-weight: 700;
}

.left-sidebar nav ul li a svg path {
  fill: var(--shade-ash);
  transition: 0.5s ease-in;
}

.active svg path {
  fill: var(--shade-black) !important;
}

.logout {
  position: absolute;
  bottom: 2rem;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 1rem;
  align-items: center;
  color: var(--shade-ash);
}

.logout:hover {
  color: var(--shade-ash);
}

.close-nav {
  display: none;
}

@media screen and (max-width: 820px) {
  /* .left-sidebar {
    display: none;
  } */

  .close-nav {
    display: flex;
    font-size: 1.5rem;
    justify-content: flex-end;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    cursor: pointer;
  }
}

.right-sidebar {
  width: 250px;
  height: 100vh;
  background: var(--lighter-blue);
  padding-right: 0rem;
  padding-left: 1rem;
  padding-top: 3rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  position: fixed;
  top: 0;
  right: 0;
}

.right-sidebar-mobile {
  width: 100%;
  height: auto;
  padding-top: 1rem;
  top: 63px;
  border-top: 1px solid var(--light-blue);
  position: absolute;
  background: var(--lighter-blue);
  padding-right: 2rem;
  padding-left: 2rem;
}

.icons-bar {
  display: flex;
  padding-top: 2rem;
  justify-content: right;
  grid-gap: 16px;
  gap: 16px;
  align-items: flex-start;
  padding-right: 1rem;
}

.notify-icon {
  position: relative;
}

.indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  border-radius: 50px;
  background: var(--button-color);
}

.notify-icon .indicator {
  position: absolute;
  right: 0;
}

.user-profile {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user-profile__name {
  display: block;
  font-size: 1.1rem;
  color: var(--shade-black);
  font-weight: 500;
  padding-top: 0.8rem;
}

.user-profile__ID {
  font-size: 0.8rem;
  color: var(--link-color);
}

.onboarding-process {
  margin-top: 3rem;
}

.onboarding-process__heading {
  color: var(--link-color);
  font-weight: 700;
}

.onboarding-progress {
  margin-top: 1rem;
}

.onboarding-progress__percent {
  color: var(--link-color);
}

.progress-wrap {
  position: relative;
}

.onboarding-bar {
  display: block;
  position: relative;
  margin-top: 0.5rem;
  background: var(--lighter-blue-2);
  width: 100%;
  height: 8px;
  border-radius: 5px;
  top: 0;
  position: absolute;
}

.onboarding-bar-active {
  position: absolute;
  background: var(--button-color);
  display: block;
  height: 8px;
  border-radius: 5px;
  top: 8px;
}

.onboarding-list {
  padding-left: 0.3rem;
  margin-top: 1rem;
  list-style: none;
}

.onboarding-list li {
  color: var(--shade-black-3);
  font-size: 1rem;
  padding-top: 1rem;
}
.onboarding-list-checkbox{
  border-radius: 50% !important;
  margin-right: 6px;
}

.close-nav-right{
  display: none;
}

@media screen and (max-width: 428px) {
  .icons-bar {
    display: none;
  }

  .user-profile {
    margin-top: 1rem;
  }

  .close-nav-right{
    display: flex;
  }
}

.ApplicationNavbar_topNav__1pRVi{
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 10;
    height: 60px;
}

.ApplicationNavbar_bottomNav__16kLy {
    height: 60px;
    background: transparent;
    margin-top: 3.75rem;
}

.ApplicationNavbar_topNav__1pRVi nav {
    display: flex;
    justify-content: flex-end;
    /* position: fixed;
    top: 0; */
}

.ApplicationNavbar_bottomNav__16kLy {
    /* height: 60px; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: #C0E7F8;
}

.ApplicationNavbar_topNav__1pRVi nav, .ApplicationNavbar_bottomNav__16kLy nav {
    height: 100%;
}
.ApplicationNavbar_logo__2oNzs{
    width: 175px;
    object-fit: cover;
    /* width: 85px; */
}

.ApplicationNavbar_topNav__1pRVi nav ul, .ApplicationNavbar_bottomNav__16kLy nav ul {
    display: flex;
    justify-content: flex-end;
    padding: 21px 44px 21px 44px;
    align-items: center;
    height: 100%;
    list-style-type: none;
    grid-gap: 20px;
    gap: 20px;
    /* width: 100%; */
}

.ApplicationNavbar_topNav__1pRVi nav ul li a {
    color: black !important;
    font-weight: bold;
}

.ApplicationNavbar_bottomNav__16kLy nav ul {
    justify-content: space-between;
    align-items: center;
}

.ApplicationNavbar_bottomNav__16kLy nav ul li:last-child {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .ApplicationNavbar_topNav__1pRVi nav ul, .ApplicationNavbar_bottomNav__16kLy nav ul {
        padding: 5px 10px 5px 20px;
    }

    .ApplicationNavbar_topNav__1pRVi nav {
        justify-content: space-between;
    }
}
.helpModal_addressItem__-7jWr{
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
 }
 b{
    font-size: large;
 }
 .helpModal_termsModal__1Kz9C{
    font-size: 15px;
 }

 @media screen and (max-width:768px) {
    .helpModal_termsModal__1Kz9C{
        font-size: 14px;
     }
 }
.search-wrapper {
  background: var(--lighter-blue);
  max-width: 300px;
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
}

.search-input {
  background: "transparent";
  border: none;
  outline: none;
}

.step-dropdown {
  margin-top: 1rem;
}

.step-heading {
  background: var(--lighter-blue);
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
}

.step-detail {
  display: flex;
  /* margin-left: 1rem; */
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
}

.step-detail .indicator {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  background: var(--button-color);
}

.step-detail .step__title {
  color: var(--regular-black);
  font-weight: 700;
  display: block;
  font-size: 1rem;
}

.step-children {
  transition: display 0.5s linear;
}

.proceed-btn{
  background-color: var(--button-color);
  /* min-height: 35px; */
  display: block;
  min-width: 100px;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 3px;
  color: var(--regular-white);
  font-weight: 600;
}

.proceed-btn:hover{
  color: var(--regular-white);
}


@media screen and (max-width:768px) {
  .step-dropdown{
    margin-top: 0;
  }
}
.step-heading{
  /* padding-top: 20px; */
  padding-top: 15px;
  padding-bottom: 15px;
}
.indicator{
  margin-right: -15px !important;
}



:root {
    --font-size: 17px;
    --link-color: rgba(1, 1, 1, 0.64);
    --button-color: #FBA834;
    --button-height: 45px;
    --button-border: rgba(95, 95, 95, 0.39);
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .AuthLayout_authLayout__bg__22EEu {
        display: none !important;
    }

    .AuthLayout_authLayout__content__1K8bl {
        width: 100vw !important;
    }
}

.AuthLayout_authLayout__qrwl_ {
    min-height: 100vh;
    height: 100vh;
    background-color: white;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
}

.AuthLayout_authLayout__wrapper__2U6n1 {
    display: flex;
    height: inherit;
}

.AuthLayout_authLayout__content__1K8bl {
    width: 55%;
}

.AuthLayout_authLayout__bg__22EEu {
    width: 45%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background: url(/static/media/AsianHHAwithClient.3d0fca20.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.AuthLayout_authLayout__content__1K8bl .AuthLayout_authFormContainer__3oI4J {
    width: 70%;
    margin: 70px auto;
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_tabLinks__r8NbO ul {
    list-style-type: none;
    display: flex;
    grid-gap: 40px;
    gap: 40px;
    color: rgba(1, 1, 1, 0.64);
    color: var(--link-color);
    padding: 0;
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_tabLinks__r8NbO ul li a {
    color: rgba(1, 1, 1, 0.64);
    color: var(--link-color);
    font-size: 17px;
    font-size: var(--font-size);
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_tabLinks__r8NbO ul li a.AuthLayout_active__1ctKm {
    font-weight: 700;
    position: relative;
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_tabLinks__r8NbO ul li  a.AuthLayout_active__1ctKm:before {
    content: '';
    position: absolute;
    background: rgba(1, 1, 1, 0.64);
    background: var(--link-color);
    height: 3px;
    width: 100%;
    bottom: -10px;
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_tabContent__CYqFg {
    margin-top: 26px;
    padding-bottom: 4rem;
}

.AuthLayout_authFormContainer__3oI4J a.AuthLayout_active__1ctKm {
    color: #4EB3E5;
}

.AuthLayout_authFormContainer__3oI4J .AuthLayout_ant-btn-primary__1U2xx {
    width: 100%;
    height: 45px !important;
    height: var(--button-height) !important;
    background: #FBA834 !important;
    background: var(--button-color) !important;
    font-size: 16px;
    border-color: rgba(95, 95, 95, 0.39) !important;
    border-color: var(--button-border) !important;
    border-radius: 3px !important;
    letter-spacing: 0.06em;
}

.authnavbar_logo__37P6e{
    width: 80px;
}
:root {
    --font-size: 17px;
    --link-color: rgba(1, 1, 1, 0.64);
    --button-color: #FBA834;
    --button-height: 45px;
    --button-border: rgba(95, 95, 95, 0.39);
}

/** mobile layout **/
@media only screen and (min-width : 320px) and (max-width : 768px){
    .default_defaultLayoutContent__3ZZ8q{
        width: 95vw !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

.default_defaultLayout__1CAzr {
    background-image: url(/static/media/AsianHHAwithClient.3d0fca20.png);
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    overflow-y: scroll;
}

.default_defaultLayout__1CAzr::after {
    /* //overlay background */
    content: '';
    min-height: inherit;
    height: inherit;
    width: inherit;
    background: rgba(10, 35, 48, 0.82);;
    position: fixed;
    left: 0;
    top: 0;
}

.default_defaultLayoutWrapper__w9czd {
    position: relative;
    z-index: 3;
    height: inherit;
}

.default_defaultLayoutContent__3ZZ8q {
    width: 45vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background: white;
    margin: 5% auto;
    border-radius: 26px;
    padding-left: 60px;
    padding-right: 58px;
    padding-top: 60px;
    padding-bottom: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.default_cardHeader__318q3 {
    font-size: 24px;
    font-weight: 700 !important;
}

.default_cardDescription__rCxsq {
    font-size: 16px;
    color: rgba(1, 1, 1, 0.72);
}
.language_selectTab__3uNtA{
    max-width: 450px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 10% auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.language_selectBtn__2T8np{
    width: 100%;
    text-align: left;
    height: 50px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    color: rgba(22, 19, 16, 0.74);
    cursor: pointer;
}
.language_selectBtn__2T8np:hover{
    color:rgba(22, 19, 16, 0.74) ;
}
.language_downOutlined__2Qw0O{
    align-self: right !important;
    align-items: flex-end;
    justify-self: flex-end;
}
.language_floatRight__2BK0a{
    float: right;
    margin-top: 10px;
    cursor: pointer;
}
.language_dropDown__1ZFxo{
    background-color: white;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 40px;
    /* transition: 3s ease-in; */
    
}
.language_dropDownText__1oRke{
    font-size: 20px;
    font-weight: 700;
    color: rgba(22, 19, 16, 0.74);
}
.language_dropDownItem__28bZq{
    margin-bottom:10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.language_dropDownItem__28bZq:hover{
    background: rgba(78, 179, 229, 0.36);
    border-radius: 5px;
}
.language_mark__1g3f6{
    color: #FBA834;
    font-weight: 900;
    font-size: 25px;
}

@media screen and (max-width:768px){
    /* .dropDown, .selectBtn{
        width: 60vw;
    } */
    .language_selectTab__3uNtA{
        margin-left: 20px;
        margin-right: 20px;
        justify-self: center;
        margin-top: 150px;
    }
    .language_selectBtn__2T8np{
        font-size: 15px;
    }
    .language_dropDownText__1oRke{
        font-size: 15px;
    }
}
:root {
  --background: #f0f9fd;
}

.application_appLayout__1mA_e {
  background: #f0f9fd;
  background: var(--background);
}

.application_appLayoutWrapper__3phXQ {
  display: flex;
  /* justify-content: space-between; */
  grid-gap: 45px;
  gap: 45px;
  padding: 20px 66px 69px 66px;
  flex-wrap: wrap;
}
.application_addressItem__1dHcM{
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
}

.application_imgSpace__1tyBv {
position: relative;
width: 180px;
height: 220px;
background: rgba(192, 231, 248, 0.29);
border: 1px solid rgba(26, 16, 6, 0.15);
border-radius: 2px;
/* left: 565.59px;
top: 0px; */
cursor: pointer;
text-align: center;
display: flex;
flex-direction: column;
/* justify-content: center; */
/* padding-top: 1rem; */
box-sizing: border-box;
border-radius: 2px;
}

.application_appSidebar__39Iio {
  width: 350px;
  display: flex;
  flex-direction: column;
  grid-gap: 21px;
  gap: 21px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  height: -webkit-max-content;
  height: max-content;
}

.application_appSidebarBox__esJIe {
  background: transparent;
  border: 1px solid rgba(101, 178, 212, 0.83);
}

.application_appSidebarBoxHeader__Ww9Ab {
  padding: 36px 27px;
}

.application_appSidebarBoxHeader__Ww9Ab h6 {
  font-size: 20px;
  font-weight: bold;
}

.application_appSidebarBoxHeader__Ww9Ab span {
  font-size: 13px;
  font-weight: bold;
  color: rgba(1, 1, 1, 0.64);
  padding-top: 10px;
}

.application_appSidebarBoxList__bTKVd ul {
  list-style-type: none;
  padding: 0;
  counter-reset: css-counter 0;
}

.application_appSidebarBox__esJIe li {
  height: 52px;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.application_appSidebarBox__esJIe li a {
  background: inherit;
  height: inherit;
  width: inherit;
  padding-left: 26px;
  color: rgba(1, 1, 1, 0.53);
  font-size: 16px;
  display: flex;
  align-items: center;
}

.application_appSidebarBox__esJIe li .application_listLinkActive__1wcAK {
  background: #1896ce !important;
  color: white;
  font-weight: bold;
  position: relative;
}

.application_appSidebarBox__esJIe li .application_listLinkActive__1wcAK::before {
  position: absolute;
  content: "";
  left: 0;
  height: 100%;
  width: 5px;
  background: #fba834;
}

.application_uploadIcon__3YH8T svg {
  fill: rgba(58, 43, 28, 0.64);
  font-size: 30px;
}
.application_addNewItemWrapper__xdJ0k{
    display: flex;
    justify-content: space-between;
    /* padding-right: 10px; */

}
.application_addNewItemWrapper__xdJ0k button {
  display: flex;
  align-items: center;
  max-width: 100px;
  grid-gap: 10px;
  gap: 10px;
  background: none;
  border: none;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.06em;
  color: rgba(1, 1, 1, 0.63);
  cursor: pointer;
  margin-bottom: 30px;
  border: 1px solid rgba(1, 1, 1, 0.63);
}

.application_formItemHeader__3vo-z {
  padding: 20px;
  padding-left: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.06em;

  color: #4eb3e5;
}

.application_appSidebarBackLink__cJb8x {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
}

.application_appSidebarBox__esJIe li a span {
  counter-increment: css-counter 1;
}

.application_appSidebarBox__esJIe li a span::before {
  content: "0" counter(css-counter);
  padding-right: 21px;
}

.application_appSidebarBox__esJIe li .application_listLinkActive__1wcAK span::before {
  content: url(/static/media/dot.50533e43.svg);
  padding-left: 10px;
  margin-right: 25px;
  padding: 0;
  padding-left: 5px;
}

.application_appContent__1MOzb {
  background: white;
  width: calc(100% - 400px);
  padding: 84px 32px 20px 32px;
}

.application_appContent__1MOzb form {
  padding-top: 30px;
}

/* .mainContent span {
  font-size: 12px;
  font-weight: bold;
} */

.application_mainContent__3tLNx h2 {
  font-size: 34px;
  font-weight: bold;
}

.application_formFooter__3E322 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.application_formFooter__3E322 .application_formSave__kLdYo {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.application_formFooter__3E322 .application_formPrevious__32aOt button {
  background: transparent !important;
  border: none;
  box-shadow: none;
  color: #fba834;
  padding-left: 0;
}

.application_formPrevious__32aOt button span {
  font-size: 16px !important;
}

.application_formSave__kLdYo button {
  background: #fba834 !important;
  background: #fba834;
  color: white;
  padding: 13px 16px;
  height: 100%;
  border-radius: 3px;
  border-color: #fba834;
  width: 10em !important;
}

.application_formSave__kLdYo button span {
  font-size: 16px;
  font-weight: normal;
}

.application_formSave__kLdYo button:first-child {
  background: white !important;
  color: #fba834;
}

/** mobile layout **/
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .application_appSidebar__39Iio,
  .application_appContent__1MOzb {
    width: 100%;
  }

  .application_formSave__kLdYo button {
    width: 8em !important;
    display: flex;
    justify-content: center;
  }

  .application_updateForm__3bAX6 {
    padding-top: 25px;
    margin-top: 30px;
  }

  .application_formPrevious__32aOt button span,
  .application_formSave__kLdYo button span {
    font-size: 14px !important;
  }

  .application_appLayoutWrapper__3phXQ {
    padding: 20px;
  }

  .application_mainContent__3tLNx h2 {
    font-size: 5vw;
  }

  .application_appContent__1MOzb {
    padding: 20px;
    margin-top: 100px;
  }

  .application_appSidebar__39Iio {
    /* display: none; */
    position: relative;
    z-index: 5;
    background-color: white;
    border: 1px solid white;
    top: 0 !important;
    /* top: -10px; */
  }

  .application_mainContent__3tLNx > span,
  .application_mainContent__3tLNx h2 {
    top: -130px;
    left: -20px;
    position: relative;
  }

  .application_mainContent__3tLNx form {
    margin-top: 30px;
  }

  .application_mainContent__3tLNx > form:first-of-type {
    margin-top: -70px;
  }

  .application_appSidebarBackLink__cJb8x span,
  .application_appSidebarBackLink__cJb8x b {
    color: #fba834;
  }

  .application_appSidebarMobileWrapper__1D61I {
    height: 100vh;
    background-color: red;
    position: relative;
    width: 100%;
    z-index: 3;
    background: rgba(24, 150, 206, 0.31);
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
  }

  .application_appSidebarMobileClose__3Enbv {
    display: flex;
    align-items: center;
    justify-content: flex-end !important;
    padding: 10px;
  }
}
@media only screen and (min-width : 320px) and (max-width : 768px){
    .application_appSidebar__39Iio, .application_appContent__1MOzb{
        width: 100%;
    }
    .application_imgSpace__1tyBv {
        /* position: absolute; */
    /* max-width: 164.53px; */
    /* width: 100vw !important; */
    font-size: 10px;
    /* background-color: red; */
    /* left: 565.59px;
    top: 0px; */

    }
    .application_formSave__kLdYo button {
        width: 8em !important;
        display: flex;
        justify-content: center;
    }

    .application_updateForm__3bAX6 {
        padding-top: 25px;
        margin-top: 30px;
    }

    .application_formPrevious__32aOt button span, .application_formSave__kLdYo button span {
        font-size: 14px !important;
    }

    .application_appLayoutWrapper__3phXQ {
        padding: 20px;
    }

    .application_mainContent__3tLNx h2{
        font-size: 5vw;
    }

    .application_appContent__1MOzb {
        padding: 20px;
        margin-top: 100px;
    }

    .application_appSidebar__39Iio {
        /* display: none; */
        position: relative;
        z-index: 5;
        background-color: white;
        border: 1px solid white;
        top: 0 !important;
        /* top: -10px; */
    }

    .application_mainContent__3tLNx > span, .application_mainContent__3tLNx h2 {
        top: -130px;
        left: -20px;
        position: relative;
    }

    .application_mainContent__3tLNx form {
        margin-top: 30px;
    }

    .application_mainContent__3tLNx > form:first-of-type {
        margin-top: -70px;
    }

    .application_appSidebarBackLink__cJb8x span, .application_appSidebarBackLink__cJb8x b{
        color: #FBA834;
    }

    .application_appSidebarMobileWrapper__1D61I {
        height: 100vh;
        background-color: red;
        position: relative;
        width: 100%;
        z-index: 3;
        background: rgba(24, 150, 206, 0.31);
        -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
    }

    .application_appSidebarMobileClose__3Enbv {
        display: flex;
        align-items: center;
        justify-content: flex-end !important;
        padding: 10px;
    }
}

.section-desc[data-v-42314ea7]{
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.section-desc__heading[data-v-42314ea7]{
  color: var(--text-Grey);
  display: block;
  font-weight: 800;
  font-size: 1rem;
}
.section-desc__info[data-v-42314ea7]{
  display: block;
  color: var(--text-blue);
  font-style: italic;
  font-weight: 500;
}
.label-tooltip[data-v-42314ea7]{
  display: flex;
  grid-gap: 1px;
  gap: 1px;
  font-size: 0.8rem;
  align-items: center;
}
.upload-count[data-v-6ab7ebee]{
  margin-bottom: 0.2rem;
}
.file-status[data-v-6ab7ebee]{
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--button-color);
}
.file-status--pending[data-v-6ab7ebee]{
  color: var(--button-color);
}
.file-status--rejected[data-v-6ab7ebee]{
  color: #FC0F3B;
}
.file-status--approved[data-v-6ab7ebee]{
  color: #15C13B;
}
.display-format[data-v-6ab7ebee]{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.display-type[data-v-6ab7ebee]{
  margin-top: 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.display-wrap[data-v-6ab7ebee]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.display-type--list[data-v-6ab7ebee]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.format-type[data-v-6ab7ebee]{
  background-color: rgba(1, 1, 1, 0.28);
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.active-display[data-v-6ab7ebee]{
  background-color: #C0E7F8;
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.icon-display[data-v-6ab7ebee]{
  font-size: 1.2rem;
  color: #01010187;
}
.icon-active[data-v-6ab7ebee]{
  font-size: 1.2rem;
  color: var(--button-color);
}
.document-viewer[data-v-6ab7ebee]{
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.preview-header[data-v-6ab7ebee]{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.8rem;
}
.preview-pane[data-v-6ab7ebee]{
  margin-top: 1rem;
  width: 460px;
  height: 400px;
  background: rgba(82, 82, 82, 0.21);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.preview-pane img[data-v-6ab7ebee]{
  max-height: 200px;
}
.doc-info[data-v-6ab7ebee]{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.doc-actions[data-v-6ab7ebee]{
  display: flex;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  justify-content: flex-end;
}
.doc-btn[data-v-6ab7ebee]{
  background: transparent;
  outline: none;
  border: none;
  color: #3A2B1C57;
  border-bottom: 1px solid #3A2B1C57;
}
.doc-btn__download[data-v-6ab7ebee]{
  color: var(--text-blue-2);
  /* border-bottom: 1px solid #3A2B1C57; */
}
.document-previews[data-v-6ab7ebee]{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.preview-img[data-v-6ab7ebee] {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.document-content[data-v-6ab7ebee]{
  margin-top: 1.5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.document-actions[data-v-6ab7ebee]{
  display: flex;
  position: absolute;
  bottom: 4px;
  justify-content: space-between;
}
.document-actions .upload-btn[data-v-6ab7ebee]{
  color: var(--text-blue);
}
.document-actions .view-btn[data-v-6ab7ebee]{
  color: var(--button-color);
}
.upload-guide[data-v-6ab7ebee]{
  display: none
}
@media screen and (max-width: 500PX){
.display-wrap[data-v-6ab7ebee]{
    margin-bottom: 4rem;
}
.upload-guide[data-v-6ab7ebee]{
    display: block;
    /* text-align: center; */
    font-size: 1.2rem;
    color: var(--button-color);
    padding-bottom: 0.5rem;
}
}

.upload-count[data-v-12cc8bb5]{
  margin-bottom: 0.2rem;
}
.file-status[data-v-12cc8bb5]{
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--button-color);
}
.file-status--pending[data-v-12cc8bb5]{
  color: var(--button-color);
}
.file-status--rejected[data-v-12cc8bb5]{
  color: #FC0F3B;
}
.file-status--approved[data-v-12cc8bb5]{
  color: #15C13B;
}
.display-format[data-v-12cc8bb5]{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.display-type[data-v-12cc8bb5]{
  margin-top: 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.display-wrap[data-v-12cc8bb5]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.display-type--list[data-v-12cc8bb5]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.format-type[data-v-12cc8bb5]{
  background-color: rgba(1, 1, 1, 0.28);
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.active-display[data-v-12cc8bb5]{
  background-color: #C0E7F8;
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.icon-display[data-v-12cc8bb5]{
  font-size: 1.2rem;
  color: #01010187;
}
.icon-active[data-v-12cc8bb5]{
  font-size: 1.2rem;
  color: var(--button-color);
}
.document-viewer[data-v-12cc8bb5]{
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.preview-header[data-v-12cc8bb5]{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.8rem;
}
.preview-pane[data-v-12cc8bb5]{
  margin-top: 1rem;
  width: 460px;
  height: 400px;
  background: rgba(82, 82, 82, 0.21);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.preview-pane img[data-v-12cc8bb5]{
  max-height: 200px;
}
.doc-info[data-v-12cc8bb5]{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.doc-actions[data-v-12cc8bb5]{
  display: flex;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  justify-content: flex-end;
}
.doc-btn[data-v-12cc8bb5]{
  background: transparent;
  outline: none;
  border: none;
  color: #3A2B1C57;
  border-bottom: 1px solid #3A2B1C57;
}
.doc-btn__download[data-v-12cc8bb5]{
  color: var(--text-blue-2);
  /* border-bottom: 1px solid #3A2B1C57; */
}
.document-previews[data-v-12cc8bb5]{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.preview-img[data-v-12cc8bb5] {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.document-content[data-v-12cc8bb5]{
  margin-top: 1.5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.document-actions[data-v-12cc8bb5]{
  display: flex;
  position: absolute;
  bottom: 4px;
  justify-content: space-between;
}
.document-actions .upload-btn[data-v-12cc8bb5]{
  color: var(--text-blue);
}
.document-actions .view-btn[data-v-12cc8bb5]{
  color: var(--button-color);
}
.upload-guide[data-v-12cc8bb5]{
  display: none
}
@media screen and (max-width: 500PX){
.display-wrap[data-v-12cc8bb5]{
    margin-bottom: 4rem;
}
.upload-guide[data-v-12cc8bb5]{
    display: block;
    /* text-align: center; */
    font-size: 1.2rem;
    color: var(--button-color);
    padding-bottom: 0.5rem;
}
}

.question-container {
  margin-bottom: 1.5rem;
}

.question-container--number {
  display: block;
  font-weight: 500;
  color: var(--regular-black);
}

.question-container--question {
  display: block;
  font-weight: 700;
  padding-top: 0.5rem;
  color: var(--regular-black);
  font-size: 1rem;
  padding-bottom: 1rem;
}

.question-answers--label {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 0.5rem;
}

.question-answers--label label {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 0.4rem 0.8rem;
  /* position: absolute; */
  left: 0;
 /* padding-bottom: 2rem; */
}

.question-answers--label label .answer-selector {
  /* background: var(--light-yellow); */
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
  border-radius: 5px;
  height: 15px;
  display: block;
  width: 15px;
  border-radius: 100%;
  border: 1px solid var(--shade-ash-2);
}

.question-answers--label input[type="radio"]:checked + label .answer-selector{
  background-color: var(--button-color);
  border: 1px solid var(--shade-black);
}

.question-answers input {
  visibility: hidden;
}

.question-answers span {
  font-weight: 500;
}

.question-answers {
  position: relative;
}

.question-answers--label input[type="radio"]:checked .question-container{
  background: var(--light-blue);
}

/* .question-answers::before {
  content: "";
  width: 1px;
  background: #0000004d;
  height: 100%;
  position: absolute;
  left: 50px;
} */

.body-wrapper[data-v-07c690ae] {
  background: var(--lighter-blue);
  height: 100vh;
  overflow-y: auto;
}
.question-wrapper[data-v-07c690ae] {
  padding: 2rem;
  background: var(--regular-white);
  margin: 1.5rem 2rem;
}
.question-wrap[data-v-07c690ae] {
  background: var(--regular-white);
  padding-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.question-wrap h1[data-v-07c690ae] {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}
.submission-popup[data-v-07c690ae]{
  background-image: url(/static/media/submit-bg.2c0f1d43.png);
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pop-content--heading[data-v-07c690ae]{
  font-size: 2.5rem;
  color: var(--button-color);
  display: block;
  text-align: center;
}
.pop-content--message[data-v-07c690ae]{
  color: var(--text-blue-2);
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.ant-modal-content[data-v-07c690ae]{
  box-shadow: none;
  border-radius: 0;
}
.w-full[data-v-07c690ae]{
  width: 100%;
  margin-top: 1rem;
  color: var(--regular-white);
}
.file-status[data-v-07c690ae]{
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--button-color);
}
@media screen and (max-width: 428px) {
.question-wrapper[data-v-07c690ae] {
    padding: 0.8rem;
    margin: 0.5rem 1rem;
}
}

.style_prompt__MjsFc{
    color: grey;
    font-weight: 600;
    /* margin-top: -15px; */
}
