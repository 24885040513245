.skip:link {
  position: fixed;
  left: 3rem;
  color: var(--shade-black) !important;
  transition: 0.3s ease-in;
  z-index: 2050;
  font-size: 1rem;
  font-weight: 300;
  background-color: var(--light-blue);
  text-decoration: none !important;
  top: -12rem;
  outline: none;
  padding: 0 2rem;
}

.skip:focus {
  top: 0;
  color: var(--shade-black);
}

.skip:hover {
  color: var(--shade-black);
}
