.question-container {
  margin-bottom: 1.5rem;
}

.question-container--number {
  display: block;
  font-weight: 500;
  color: var(--regular-black);
}

.question-container--question {
  display: block;
  font-weight: 700;
  padding-top: 0.5rem;
  color: var(--regular-black);
  font-size: 1rem;
  padding-bottom: 1rem;
}

.question-answers--label {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 0.5rem;
}

.question-answers--label label {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 0.4rem 0.8rem;
  /* position: absolute; */
  left: 0;
 /* padding-bottom: 2rem; */
}

.question-answers--label label .answer-selector {
  /* background: var(--light-yellow); */
  /* padding-left: 0.5rem; */
  /* padding-right: 0.5rem; */
  border-radius: 5px;
  height: 15px;
  display: block;
  width: 15px;
  border-radius: 100%;
  border: 1px solid var(--shade-ash-2);
}

.question-answers--label input[type="radio"]:checked + label .answer-selector{
  background-color: var(--button-color);
  border: 1px solid var(--shade-black);
}

.question-answers input {
  visibility: hidden;
}

.question-answers span {
  font-weight: 500;
}

.question-answers {
  position: relative;
}

.question-answers--label input[type="radio"]:checked .question-container{
  background: var(--light-blue);
}

/* .question-answers::before {
  content: "";
  width: 1px;
  background: #0000004d;
  height: 100%;
  position: absolute;
  left: 50px;
} */
