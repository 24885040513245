.right-sidebar {
  width: 250px;
  height: 100vh;
  background: var(--lighter-blue);
  padding-right: 0rem;
  padding-left: 1rem;
  padding-top: 3rem;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  position: fixed;
  top: 0;
  right: 0;
}

.right-sidebar-mobile {
  width: 100%;
  height: auto;
  padding-top: 1rem;
  top: 63px;
  border-top: 1px solid var(--light-blue);
  position: absolute;
  background: var(--lighter-blue);
  padding-right: 2rem;
  padding-left: 2rem;
}

.icons-bar {
  display: flex;
  padding-top: 2rem;
  justify-content: right;
  grid-gap: 16px;
  gap: 16px;
  align-items: flex-start;
  padding-right: 1rem;
}

.notify-icon {
  position: relative;
}

.indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  border-radius: 50px;
  background: var(--button-color);
}

.notify-icon .indicator {
  position: absolute;
  right: 0;
}

.user-profile {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user-profile__name {
  display: block;
  font-size: 1.1rem;
  color: var(--shade-black);
  font-weight: 500;
  padding-top: 0.8rem;
}

.user-profile__ID {
  font-size: 0.8rem;
  color: var(--link-color);
}

.onboarding-process {
  margin-top: 3rem;
}

.onboarding-process__heading {
  color: var(--link-color);
  font-weight: 700;
}

.onboarding-progress {
  margin-top: 1rem;
}

.onboarding-progress__percent {
  color: var(--link-color);
}

.progress-wrap {
  position: relative;
}

.onboarding-bar {
  display: block;
  position: relative;
  margin-top: 0.5rem;
  background: var(--lighter-blue-2);
  width: 100%;
  height: 8px;
  border-radius: 5px;
  top: 0;
  position: absolute;
}

.onboarding-bar-active {
  position: absolute;
  background: var(--button-color);
  display: block;
  height: 8px;
  border-radius: 5px;
  top: 8px;
}

.onboarding-list {
  padding-left: 0.3rem;
  margin-top: 1rem;
  list-style: none;
}

.onboarding-list li {
  color: var(--shade-black-3);
  font-size: 1rem;
  padding-top: 1rem;
}
.onboarding-list-checkbox{
  border-radius: 50% !important;
  margin-right: 6px;
}

.close-nav-right{
  display: none;
}

@media screen and (max-width: 428px) {
  .icons-bar {
    display: none;
  }

  .user-profile {
    margin-top: 1rem;
  }

  .close-nav-right{
    display: flex;
  }
}
