.dashboard-wrapper[data-v-54ab280f] {
  padding: 2rem;
  padding-top: 3rem;
}
.welcome-wrapper[data-v-54ab280f] {
  background: var(--light-blue);
  border-radius: 5px;
  margin-top: 1.5rem;
  padding: 2rem 1.5rem;
}
.welcome-wrapper h1[data-v-54ab280f] {
  font-size: 2rem;
  font-weight: 500;
  color: var(--shade-black-2);
  margin-bottom: 0;
}
.welcome-wrapper span[data-v-54ab280f] {
  margin-top: -0.5rem;
  font-size: 1rem;
  display: block;
  font-weight: 500;
  color: var(--shade-black-2);
}
.applicants-todo[data-v-54ab280f] {
  margin-top: 1rem;
}
.applicants-todo h2[data-v-54ab280f] {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--regular-black);
}
.missing-table[data-v-54ab280f] {
  background: var(--light-blue);
}
.missing-table table thead tr th[data-v-54ab280f] {
  background: var(--light-blue) !important;
  font-weight: 700;
}
.missing-table table tbody tr[data-v-54ab280f] {
  background: var(--light-blue) !important;
}
@media screen and (max-width:560px) {
.home-user-name[data-v-54ab280f]{
    font-size: 25px !important;
    font-weight: 600;
    margin-bottom: 10px !important;
}
}
