.body-wrapper[data-v-07c690ae] {
  background: var(--lighter-blue);
  height: 100vh;
  overflow-y: auto;
}
.question-wrapper[data-v-07c690ae] {
  padding: 2rem;
  background: var(--regular-white);
  margin: 1.5rem 2rem;
}
.question-wrap[data-v-07c690ae] {
  background: var(--regular-white);
  padding-bottom: 2rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
.question-wrap h1[data-v-07c690ae] {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
}
.submission-popup[data-v-07c690ae]{
  background-image: url("../../../../assets/img/submit-bg.png");
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pop-content--heading[data-v-07c690ae]{
  font-size: 2.5rem;
  color: var(--button-color);
  display: block;
  text-align: center;
}
.pop-content--message[data-v-07c690ae]{
  color: var(--text-blue-2);
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.ant-modal-content[data-v-07c690ae]{
  box-shadow: none;
  border-radius: 0;
}
.w-full[data-v-07c690ae]{
  width: 100%;
  margin-top: 1rem;
  color: var(--regular-white);
}
.file-status[data-v-07c690ae]{
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--button-color);
}
@media screen and (max-width: 428px) {
.question-wrapper[data-v-07c690ae] {
    padding: 0.8rem;
    margin: 0.5rem 1rem;
}
}
