.main-section {
  margin-left: 250px;
  margin-right: 250px;
}

.mobile-profile-icons {
  display: none;
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 820px) {
  .main-section {
    margin-left: 0;
    /* margin-right: 0; */
  }

  .mobile-nav {
    padding: 1rem;
    background: var(--lighter-blue);
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 60px;
  }
  .main-section{
    margin-top: 4rem;
  }
}

@media screen and (max-width: 428px) {
  .main-section {
    margin-right: 0;
  }

  .mobile-profile-icons {
    display: flex;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
  }
}
