@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,200");
@import url("https://fonts.googleapis.com/css?family=Roboto:300");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css");

:root {
  --input-label-color: #010101a3;
  --link-color: rgba(1, 1, 1, 0.64);
  --button-color: #fba834;
  --button-text-color: #000000;
  --button-height: 45px;
  --button-border: rgba(95, 95, 95, 0.39);

  /* ------------ blue color shades ----------- */
  --text-blue: #4eb3e5;
  --text-blue-2: #429ECB;
  --light-blue: #c0e7f8;
  --lighter-blue: #f0f9fd;
  --lighter-blue-2: rgba(78, 179, 229, 0.15);

  /* ---------- grey color shades --------- */
  --text-Grey: rgba(58, 43, 28, 0.74);

  /* ----------- ash color shades ------------ */
  --shade-ash: rgba(23, 18, 25, 0.6);
  --shade-ash-2: #c4c4c4;

  /* ---------- black color shades ------------ */
  --shade-black: #010101;
  --shade-black-2: #171219;
  --shade-black-3: rgba(23, 18, 25, 0.81);
  --regular-black: #000000;

  /* ----------- white color shades ---------- */
  --regular-white: #ffffff;

  /* --------- yellow color shades ----------- */
  --light-yellow: #fba83487;
}

/** font sizing **/
@media only screen and (min-width: 320px) and (max-width: 768px) {
  h5 {
    font-size: 5vw !important;
  }
  h6 {
    font-size: 4vw !important;
  }
}
* {
  font-family: "Lato", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
}

.register-tooltip{
  color: #ff9500;
  margin-left: 6px;
  cursor: pointer;
}
.hcrDocs{
  height: 250px;
  width: 250px;
}
.ant-toolTip{
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background-color: unset;
}
/* //ant overrides // */


.ant-input {
  height: 45px !important;
  border-radius: 3px !important;
  border: 1px solid rgba(95, 95, 95, 0.39);
}

.ant-select,
.ant-select-selector {
  height: 45px !important;
}

.ant-select-selector {
  border-radius: 3px !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-form-item-label label {
  color: #010101a3 !important;
  color: var(--input-label-color) !important;
  letter-spacing: 0.06em;
}

.ant-input-affix-wrapper {
  padding: 0 !important;
  padding-left: 11px !important;
  border: 1px solid rgba(95, 95, 95, 0.39) !important;
  border-radius: 3px !important;
  height: 45px !important;
}

.ant-input-affix-wrapper input {
  height: inherit !important;
  background-color: transparent !important;
}

.ant-input-suffix {
  padding: 11px !important;
}

.ant-table-wrapper {
  overflow-x: auto;
}

.auth-btn--custom-auth,
.ant-picker {
  width: 100%;
  height: 45px !important;
  height: var(--button-height) !important;
  background: #fba834 !important;
  background: var(--button-color) !important;
  color: #000000 !important;
  color: var(--button-text-color) !important;
  font-size: 16px;
  border-color: rgba(95, 95, 95, 0.39) !important;
  border-color: var(--button-border) !important;
  border-radius: 3px !important;
  letter-spacing: 0.06em;
}

.ant-picker {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
}

.bold {
  font-weight: bold;
}

.text--blue {
  color: #4eb3e5;
  color: var(--text-blue);
}

.form-heading {
  font-size: 1rem !important;
  font-weight: 700 !important;
  color: rgba(58, 43, 28, 0.74);
  color: var(--text-Grey);
  display: block;
}

.img-temp {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #c4c4c4;
  background: var(--shade-ash-2);
}

.img-temp--mobile {
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
}

.btn {
  color: #000000 !important;
  color: var(--button-text-color) !important;
  background: #fba834 !important;
  background: var(--button-color) !important;
  min-height: 45px;
  min-width: 120px;
  border-radius: 5px !important;
  font-weight: 700;
}

.btn:disabled {
  opacity: 0.5;
}

/* ------------ padding & margin classes ----------- */
.ml-auto {
  margin-left: auto;
}

/* ----------- display helpers ---------- */
.block {
  display: block;
}
