.selectTab{
    max-width: 450px;
    height: fit-content;
    margin: 10% auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.selectBtn{
    width: 100%;
    text-align: left;
    height: 50px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 700;
    color: rgba(22, 19, 16, 0.74);
    cursor: pointer;
}
.selectBtn:hover{
    color:rgba(22, 19, 16, 0.74) ;
}
.downOutlined{
    align-self: right !important;
    align-items: flex-end;
    justify-self: flex-end;
}
.floatRight{
    float: right;
    margin-top: 10px;
    cursor: pointer;
}
.dropDown{
    background-color: white;
    width: 100%;
    margin-top: 20px;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 40px;
    /* transition: 3s ease-in; */
    
}
.dropDownText{
    font-size: 20px;
    font-weight: 700;
    color: rgba(22, 19, 16, 0.74);
}
.dropDownItem{
    margin-bottom:10px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
}
.dropDownItem:hover{
    background: rgba(78, 179, 229, 0.36);
    border-radius: 5px;
}
.mark{
    color: #FBA834;
    font-weight: 900;
    font-size: 25px;
}

@media screen and (max-width:768px){
    /* .dropDown, .selectBtn{
        width: 60vw;
    } */
    .selectTab{
        margin-left: 20px;
        margin-right: 20px;
        justify-self: center;
        margin-top: 150px;
    }
    .selectBtn{
        font-size: 15px;
    }
    .dropDownText{
        font-size: 15px;
    }
}