.upload-count[data-v-12cc8bb5]{
  margin-bottom: 0.2rem;
}
.file-status[data-v-12cc8bb5]{
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--button-color);
}
.file-status--pending[data-v-12cc8bb5]{
  color: var(--button-color);
}
.file-status--rejected[data-v-12cc8bb5]{
  color: #FC0F3B;
}
.file-status--approved[data-v-12cc8bb5]{
  color: #15C13B;
}
.display-format[data-v-12cc8bb5]{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}
.display-type[data-v-12cc8bb5]{
  margin-top: 1rem;
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.display-wrap[data-v-12cc8bb5]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.display-type--list[data-v-12cc8bb5]{
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.format-type[data-v-12cc8bb5]{
  background-color: rgba(1, 1, 1, 0.28);
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.active-display[data-v-12cc8bb5]{
  background-color: #C0E7F8;
  padding: 0.4rem;
  transition: 0.5s ease-in;
  border-radius: 2px;
  cursor: pointer;
}
.icon-display[data-v-12cc8bb5]{
  font-size: 1.2rem;
  color: #01010187;
}
.icon-active[data-v-12cc8bb5]{
  font-size: 1.2rem;
  color: var(--button-color);
}
.document-viewer[data-v-12cc8bb5]{
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
.preview-header[data-v-12cc8bb5]{
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.8rem;
}
.preview-pane[data-v-12cc8bb5]{
  margin-top: 1rem;
  width: 460px;
  height: 400px;
  background: rgba(82, 82, 82, 0.21);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.preview-pane img[data-v-12cc8bb5]{
  max-height: 200px;
}
.doc-info[data-v-12cc8bb5]{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.doc-actions[data-v-12cc8bb5]{
  display: flex;
  position: absolute;
  bottom: 1rem;
  width: 100%;
  justify-content: flex-end;
}
.doc-btn[data-v-12cc8bb5]{
  background: transparent;
  outline: none;
  border: none;
  color: #3A2B1C57;
  border-bottom: 1px solid #3A2B1C57;
}
.doc-btn__download[data-v-12cc8bb5]{
  color: var(--text-blue-2);
  /* border-bottom: 1px solid #3A2B1C57; */
}
.document-previews[data-v-12cc8bb5]{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.preview-img[data-v-12cc8bb5] {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}
.document-content[data-v-12cc8bb5]{
  margin-top: 1.5rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
.document-actions[data-v-12cc8bb5]{
  display: flex;
  position: absolute;
  bottom: 4px;
  justify-content: space-between;
}
.document-actions .upload-btn[data-v-12cc8bb5]{
  color: var(--text-blue);
}
.document-actions .view-btn[data-v-12cc8bb5]{
  color: var(--button-color);
}
.upload-guide[data-v-12cc8bb5]{
  display: none
}
@media screen and (max-width: 500PX){
.display-wrap[data-v-12cc8bb5]{
    margin-bottom: 4rem;
}
.upload-guide[data-v-12cc8bb5]{
    display: block;
    /* text-align: center; */
    font-size: 1.2rem;
    color: var(--button-color);
    padding-bottom: 0.5rem;
}
}
