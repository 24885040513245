.main[data-v-d9198839]{
  background: linear-gradient(180deg, rgba(9, 75, 104, 0.7) 0%, rgba(7, 55, 76, 0.196) 134.98%), url("../../assets/img/404-bg.png");
  height: 100vh;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading[data-v-d9198839]{
  font-size: 10rem;
  font-weight: 600;
  color: var(--button-color);
  text-align: center;
  margin-bottom: 0;
  line-height: 1;
}
.not-found[data-v-d9198839]{
  color: var(--regular-white);
  text-transform: uppercase;
  font-size: 3rem;
  display: block;
  text-align: center;
  font-weight: 600;
}
.not-found-details[data-v-d9198839]{
  color: var(--regular-white);
  text-align: center;
  font-size: 1rem;
}
.return-btn[data-v-d9198839]{
  display: block;
  min-width:200px;
  background: var(--regular-white);
  text-align: center;
  padding: 0.8rem;
  color: #201F1F;
  border: 2px solid #010101;
  box-shadow: 3px 4px #fba834, 5px 6px #201F1F ;
}

/* box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px; */